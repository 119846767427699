<template>
  <div>
    <member-menu/>
  <div class="iq-card p-1 text-center">
    <welcome-page welcomeMessage="welcome To Member Page"></welcome-page>
  </div>
  </div>
</template>
<script>
export default {
  name: 'welcomeMember'
}
</script>
